import type { ObjectValues } from 'common/types/util';

export enum ContextTypes {
  autopilot = 'autopilot',
  changelogEntryImage = 'changelogEntryImage',
  commentFile = 'commentFile',
  commentImage = 'commentImage',
  companyFavicon = 'companyFavicon',
  companyLogo = 'companyLogo',
  postFile = 'postFile',
  postImage = 'postImage',
  thirdPartyUserAvatar = 'thirdPartyUserAvatar',
  userAvatar = 'userAvatar',
}

export enum FileFolders {
  files = 'files',
  images = 'images',
}

// Note: The first value in the array is the primary mime type
export const MimeTypes = {
  png: ['image/png'],
  jpeg: ['image/jpeg'],
  jpg: ['image/jpeg'],
  gif: ['image/gif'],
  webp: ['image/webp'],
  svg: ['image/svg+xml'],
  txt: ['text/plain'],
  rtf: ['application/rtf', 'text/rtf'],
  csv: ['text/csv'],
  md: ['text/markdown'],
  html: ['text/html'],
  yaml: ['application/x-yaml'],
  pdf: ['application/pdf'],
  doc: ['application/msword'],
  docx: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  xls: ['application/vnd.ms-excel'],
  xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  ppt: ['application/vnd.ms-powerpoint'],
  pptx: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  odt: ['application/vnd.oasis.opendocument.text'],
  ods: ['application/vnd.oasis.opendocument.spreadsheet'],
  odp: ['application/vnd.oasis.opendocument.presentation'],
  key: ['application/x-iwork-keynote-sffkey'],
  json: ['application/json'],
  xml: ['application/xml', 'text/xml'],
  mp4: ['video/mp4'],
  avi: ['video/x-msvideo', 'video/avi'],
  mov: ['video/quicktime'],
  wmv: ['video/x-ms-wmv'],
  flv: ['video/x-flv'],
} as const;

export type ExtensionTypes = Extract<keyof typeof MimeTypes, string>;
export type MimeTypeValues = ObjectValues<typeof MimeTypes>[number];

// Create the Extensions enum using the keys from MimeTypes
export const Extensions = Object.freeze(
  Object.fromEntries(Object.keys(MimeTypes).map((key) => [key, key])) as {
    [K in ExtensionTypes]: K;
  }
);

export const isSupportedExtension = (extension: string): extension is ExtensionTypes => {
  return extension in Extensions;
};

export const AllMimeTypes = Object.values(MimeTypes).flat();

export const ImageMimeTypes: MimeTypeValues[] = [
  ...MimeTypes.png,
  ...MimeTypes.jpeg,
  ...MimeTypes.gif,
  ...MimeTypes.webp,
  ...MimeTypes.svg,
];

export type FileWithMetadata = {
  buffer: Buffer;
  contentLength: number;
  contentType: MimeTypeValues;
};

export enum LoadStatus {
  failed = 'failed',
  loading = 'loading',
  loaded = 'loaded',
  notStarted = 'notStarted',
}

export type LocalFile = {
  extension: ExtensionTypes | null;
  name: string;
  contentType: MimeTypeValues | null;
  uniqueID: string;
  uploadStatus: LoadStatus;
  url: string | null;
};

export type FailedFile = LocalFile & { uploadStatus: LoadStatus.failed; url: null };
export type UploadingFile = LocalFile & { uploadStatus: LoadStatus.loading; url: null };
export type UploadedFile = LocalFile & { uploadStatus: LoadStatus.loaded; url: string };

export enum ObjectTypes {
  changelogEntry = 'changelogEntry',
  comment = 'comment',
  company = 'company',
  featureExtractionItem = 'featureExtractionItem',
  post = 'post',
  thirdPartyUser = 'thirdPartyUser',
  user = 'user',
}

export type ObjectFile = { name: string; url: string };
