export enum IdeaFieldTypes {
  boolean = 'boolean',
  date = 'date',
  dropdown = 'dropdown',
  group = 'group',
  number = 'number',
  multilineText = 'multilineText',
  multiselect = 'multiselect',
  text = 'text',
  user = 'user',
  url = 'url',
}

export enum DefaultFieldNames {
  Author = 'author',
  Category = 'category',
  Created = 'created',
  Group = 'group',
  Owner = 'owner',
  Status = 'status',
  Tags = 'tags',
  Title = 'title',
  Type = 'type',
}

export enum IdeaImpactFieldNames {
  companies = 'companies',
  monthlySpend = 'monthlySpend',
  users = 'users',
}

// helper type to map IdeaFieldTypes to their corresponding TypeScript types
export type FieldTypeMap = {
  [IdeaFieldTypes.boolean]: boolean;
  [IdeaFieldTypes.date]: string; // stored as ISO string
  [IdeaFieldTypes.dropdown]: string;
  [IdeaFieldTypes.group]: string;
  [IdeaFieldTypes.number]: number;
  [IdeaFieldTypes.multilineText]: string;
  [IdeaFieldTypes.multiselect]: string[];
  [IdeaFieldTypes.text]: string;
  [IdeaFieldTypes.user]: string;
  [IdeaFieldTypes.url]: string;
};

export type DefaultFieldTypeMap = {
  [DefaultFieldNames.Author]: IdeaFieldTypes.user;
  [DefaultFieldNames.Category]: IdeaFieldTypes.text;
  [DefaultFieldNames.Created]: IdeaFieldTypes.date;
  [DefaultFieldNames.Group]: IdeaFieldTypes.group;
  [DefaultFieldNames.Owner]: IdeaFieldTypes.user;
  [DefaultFieldNames.Status]: IdeaFieldTypes.text;
  [DefaultFieldNames.Tags]: IdeaFieldTypes.multiselect;
  [DefaultFieldNames.Title]: IdeaFieldTypes.text;
  [DefaultFieldNames.Type]: IdeaFieldTypes.text;
};

export const CustomIdeaFieldTypesList = Object.values(IdeaFieldTypes).filter(
  (type) => type !== IdeaFieldTypes.group
);

// The maximum number of options for a custom idea dropdown or multiselect field
export const MaxFieldOptions = 25;

// The maximum number of characters for a custom idea field option
export const MaxFieldOptionCharLength = 30;

export const IdeaFieldTypeConfig: Record<
  IdeaFieldTypes,
  {
    hasOptions: boolean;
  }
> = {
  [IdeaFieldTypes.dropdown]: {
    hasOptions: true,
  },
  [IdeaFieldTypes.multiselect]: {
    hasOptions: true,
  },
  [IdeaFieldTypes.boolean]: {
    hasOptions: false,
  },
  [IdeaFieldTypes.date]: {
    hasOptions: false,
  },
  [IdeaFieldTypes.group]: {
    hasOptions: false,
  },
  [IdeaFieldTypes.number]: {
    hasOptions: false,
  },
  [IdeaFieldTypes.multilineText]: {
    hasOptions: false,
  },
  [IdeaFieldTypes.text]: {
    hasOptions: false,
  },
  [IdeaFieldTypes.user]: {
    hasOptions: false,
  },
  [IdeaFieldTypes.url]: {
    hasOptions: false,
  },
};
